:root{
    --logo: "\e929";

    --header-text-color: #00A9E0;
    --header-background-color: #ffffff;

    --heading-text-color: #00A9E0;

    --link-text-color: #00A9E0;
    --link-accent-color: #3D3393;

    --button-text-color: #ffffff;
    --button-background-color: #00A9E0;

    --button-accent-text-color: #3D3393;
    --button-accent-background-color: #00FF84;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #00A9E0;
    --form-accent-color: #00A9E0;

    --list-heading-text-color: #3D3393;
    --list-heading-background-color: #00FDDC;
    --list-border-color: #3D3393;

    --option-first-color: #00A9E0;
    --option-second-color: #00FF84;
}
/* -------- PROGRAMMES ---------- */
[skin="steps"]{
    --logo: "\e908";

    --header-text-color: #744FC6;
    --header-background-color: #E4FF1A;

    --heading-text-color: #744FC6;

    --link-text-color: #00A9E0;
    --link-accent-color: #1DD3B0;

    --button-text-color: #ffffff;
    --button-background-color: #1DD3B0;

    --button-accent-text-color: #E4FF1A;
    --button-accent-background-color: #744FC6;

    --form-heading-text-color: #E4FF1A;
    --form-heading-background-color: #744FC6;
    --form-accent-color: #1DD3B0;

    --list-heading-text-color: #744FC6;
    --list-heading-background-color: #E4FF1A;
    --list-border-color: #E4FF1A;

    --option-first-color: #744FC6;
    --option-second-color: #1DD3B0;
}

[skin="reach"]{
    --logo: "\e901";

    --header-text-color: #003349;
    --header-background-color: #FFD100;

    --heading-text-color: #003349;

    --link-text-color: #00A9E0;
    --link-accent-color: #003349;

    --button-text-color: #ffffff;
    --button-background-color: #00A9E0;

    --button-accent-text-color: #DAFFED;
    --button-accent-background-color: #003349;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #00A9E0;
    --form-accent-color: #00A9E0;

    --list-heading-text-color: #003349;
    --list-heading-background-color: #FFD100;
    --list-border-color: #FFD100;

    --option-first-color: #00A9E0;
    --option-second-color: #DB3A34;
}

[skin="myfuture"]{
    --logo: "\e900";

    --header-text-color: #3E87CB;
    --header-background-color: #FEDB00;

    --heading-text-color: #3E87CB;

    --link-text-color: #3E87CB;
    --link-accent-color: #150578;

    --button-text-color: #ffffff;
    --button-background-color: #150578;

    --button-accent-text-color: #FEDB00;
    --button-accent-background-color: #3E87CB;

    --form-heading-text-color: #3E87CB;
    --form-heading-background-color: #FEDB00;
    --form-accent-color: #3E87CB;

    --list-heading-text-color: #3E87CB;
    --list-heading-background-color: #FEDB00;
    --list-border-color: #FEDB00;

    --option-first-color: #150578;
    --option-second-color: #3E87CB;
}
/* -------- COMMUNITIES ---------- */
[skin="school"]{
    --header-text-color: #ffffff;
    --header-background-color: #487B7C;

    --heading-text-color: #487B7C;

    --link-text-color: #7DD181;
    --link-accent-color: #DB5461;

    --button-text-color: #ffffff;
    --button-background-color: #7DD181;

    --button-accent-text-color: #ffffff;
    --button-accent-background-color: #DB5461;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #487B7C;
    --form-accent-color: #487B7C;

    --list-heading-text-color: #ffffff;
    --list-heading-background-color: #487B7C;
    --list-border-color: #487B7C;

    --option-first-color: #7DD181;
    --option-second-color: #DB5461;
}
[skin="graduates"]{
    --header-text-color: #ffffff;
    --header-background-color: #B63437;

    --heading-text-color: #B63437;

    --link-text-color: #84DCC6;
    --link-accent-color: #12355B;

    --button-text-color: #ffffff;
    --button-background-color: #84DCC6;

    --button-accent-text-color: #ffffff;
    --button-accent-background-color: #12355B;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #B63437;
    --form-accent-color: #B63437;

    --list-heading-text-color: #ffffff;
    --list-heading-background-color: #B63437;
    --list-border-color: #B63437;

    --option-first-color: #84DCC6;
    --option-second-color: #B63437;
}
[skin="adultlearners"]{
    --header-text-color: #ffffff;
    --header-background-color: #DE800C;

    --heading-text-color: #DE800C;

    --link-text-color: #1A535C;
    --link-accent-color: #902D41;

    --button-text-color: #ffffff;
    --button-background-color: #1A535C;

    --button-accent-text-color: #ffffff;
    --button-accent-background-color: #902D41;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #DE800C;
    --form-accent-color: #DE800C;

    --list-heading-text-color: #ffffff;
    --list-heading-background-color: #DE800C;
    --list-border-color: #DE800C;

    --option-first-color: #1A535C;
    --option-second-color: #DE800C;
}
[skin="jobseekers"]{
    --header-text-color: #ffffff;
    --header-background-color: #1F8AF5;

    --heading-text-color: #1F8AF5;

    --link-text-color: #1F8AF5;
    --link-accent-color: #153243;

    --button-text-color: #153243;
    --button-background-color: #FFE74C;

    --button-accent-text-color: #ffffff;
    --button-accent-background-color: #153243;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #1F8AF5;
    --form-accent-color: #1F8AF5;

    --list-heading-text-color: #ffffff;
    --list-heading-background-color: #1F8AF5;
    --list-border-color: #1F8AF5;

    --option-first-color: #1F8AF5;
    --option-second-color: #153243;
}
[skin="parents"]{
    --header-text-color: #ffffff;
    --header-background-color: #484F5B;

    --heading-text-color: #484F5B;

    --link-text-color: #ED6A5A;
    --link-accent-color: #95F2D9;

    --button-text-color: #ffffff;
    --button-background-color: #ED6A5A;

    --button-accent-text-color: #484F5B;
    --button-accent-background-color: #95F2D9;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #484F5B;
    --form-accent-color: #484F5B;

    --list-heading-text-color: #ffffff;
    --list-heading-background-color: #484F5B;
    --list-border-color: #484F5B;

    --option-first-color: #95F2D9;
    --option-second-color: #ED6A5A;
}
[skin="guidance"]{
    --header-text-color: #ffffff;
    --header-background-color: #354593;

    --heading-text-color: #354593;

    --link-text-color: #E83151;
    --link-accent-color: #354593;

    --button-text-color: #354593;
    --button-background-color: #E5E059;

    --button-accent-text-color: #ffffff;
    --button-accent-background-color: #E83151;

    --form-heading-text-color: #ffffff;
    --form-heading-background-color: #354593;
    --form-accent-color: #354593;

    --list-heading-text-color: #ffffff;
    --list-heading-background-color: #354593;
    --list-border-color: #354593;

    --option-first-color: #354593;
    --option-second-color: #E83151;
}