// settings
@import "settings/skins";
@import "settings/mixins";
@import "settings/colors";
@import "settings/typography";
@import "settings/variables";

.mod--communities {
    
    @import "bootstrap/grid";

    .w-100{
        width: 100% !important;
    }
    
    .container{
        max-width: 1280px !important;
    }

    .card{
        font-size: .85rem;
        p:last-child{
            margin-bottom: 0;
        }
        a{
            position: relative;
            overflow: hidden;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 1rem;
                background: var(--heading-text-color);
                opacity: 0;
                transition: opacity 300ms ease-in-out;
            }
            &:hover{
                &:before{
                    opacity: 0.1;
                }
            }
        }
        .content{
            color: $text-color;
        }
        h1,h2,h3,h4,h5,h6{
            color: var(--heading-text-color);
        }
    }
    // .wrapper {
    //     max-width: 1280px;
    //     margin: auto;
    // }

    // ul {
    //     list-style: none;
    //     margin: 0;
    //     padding: 0;
    //     display: flex;
    //     align-items: center;
    //     flex-wrap: wrap;
    //     li {
    //         box-sizing: border-box;
    //         flex-basis: calc(100%/3);
    //         /* EDGE ONLY */
    //         @supports (-ms-ime-align: auto) {
    //             flex-basis: floor(100%/3);
    //         }
    //         /* IE ONLY */
    //         @media screen and (-ms-high-contrast: active),
    //         (-ms-high-contrast: none) {
    //             flex-basis: floor(100%/3 - .1%);
    //         }
    //         @include media("tablet-wide", max) {
    //             flex-basis: 50%;
    //             /* EDGE ONLY */
    //             @supports (-ms-ime-align: auto) {
    //                 flex-basis: floor(50%);
    //             }
    //             /* IE ONLY */
    //             @media screen and (-ms-high-contrast: active),
    //             (-ms-high-contrast: none) {
    //                 flex-basis: floor(50% - .1%);
    //             }
    //         }
    //         @include media("phone-wide", max) {
    //             flex-basis: 100%;
    //         }
    //         a {
    //             display: flex;
    //             align-items: center;
    //             color: $text-color;
    //             border-radius: 1rem;
    //             padding: 2rem;
    //             @include media("tablet-wide", max) {
    //                 padding: 1em;
    //             }
    //         }
    //         .heading {
    //             font-size: 1.2em;
    //             margin-bottom: .5rem;
    //             @include media("tablet-wide", max) {
    //                 font-size: 1em;
    //             }
    //         }
    //         p {
    //             font-size: .85em;
    //         }
    //         img {
    //             width: 6.5em;
    //             max-width: 6.5em;
    //             min-width: 6.5em;
    //             margin-right: 1rem;
    //             @include media("tablet-wide", max) {
    //                 width: 4.5em;
    //                 max-width: 4.5em;
    //                 min-width: 4.5em;
    //             }
    //         }
    //         @mixin cpbulbaCommunitiesColours($id, $color1, $color2) {
    //             &[data-color="#{$id}"] {
    //                 a {
    //                     &:hover {
    //                         background: rgba($color1, 0.1);
    //                     }
    //                 }
    //                 .heading {
    //                     color: $color1;
    //                 }
    //             }
    //         }
    //         @each $id,
    //         $data in $groups-scheme {
    //             @include cpbulbaCommunitiesColours($id, nth($data, 1), nth($data, 2));
    //         }
    //     }
    // }
}